<template>
  <Web>
    <div class="container">
      <h1>{{ $t('Here you find the results of the completed questionnaire \'religiosity/spirituality and coping\'.') }}</h1>
      <div class="bg-light mb-6 p-2">
        <table class="table">
          <tr>
            <td>{{ $t('Total points:') }}</td>
            <td>{{ score }}</td>
          </tr>
          <tr>
            <td>{{ $t('Unique ID:') }}</td>
            <td>{{ uuid }}</td>
          </tr>
        </table>
      </div>

      <p>{{ $t('The results are on the one hand a snapshot, but on the other hand they have a little more to say than that. It\'s about self-reflection. Because if you become aware of something, you can also decide whether you want to do something with it or that you want to leave it like it is and becontent.') }}</p>
      <p>{{ $t('The Mature Religiosity Scale (MRS) indicates the extent of integration of the faith (in daily life) and the ripening. What the results indicate is how you currently score in comparison to people who are in similar circumstances, as has been shown by previous scientific research. A distinction is made here between people who are in treatment with a care provider and people who are not. It is not a normative or objective score, but a score that can be helpful in determining whether there are certain areas in your life that you want to give more attention to or that you want to gain more insight into.') }}</p>
      <p>
        {{ $t('The Mature Religiosity Scale can be regarded as a whole. This list consists of 16 items, namely the following:') }}<br>
        {{ $t('1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 17, 18, 19, 22 (in other words, all items except 4, 14, 15, 16, 20, 21, 23).') }}
      </p>
      <p>{{ $t('A score can be calculated from this. This appears to be different for people with and without treatment.') }}</p>
      <p>
        {{ $t('It is possible to score the answers as follows:') }}<br>
        <ul class="unstyled">
            <li>{{ $t('1 point for ‘totally applicable’') }}</li>
            <li>{{ $t('2 points for ‘applicable’') }}</li>
            <li>{{ $t('3 points for ‘applicable/ not applicable’') }}</li>
            <li>{{ $t('4 points for ‘not applicable’') }}</li>
            <li>{{ $t('5 points for ‘totally not applicable’') }}</li>
        </ul>
      </p>
      <p>{{ $t('For the total score the points of the 16 items are added up. The lower the score, the more ‘mature’ religiosity.') }}</p>
      <p>{{ $t('The mean score of the group without treatment was 27.5 and of the group with treatment 32.4. For standardization the scores are divided in 4 groups, which are labelled from high to low.') }}</p>
      <p>{{ $t('The scoring for the respondents ‘without treatment’ (mean score 27.5) is as follows:') }}<br><br>
          {{ $t('Number of points:') }}<br>
          <ol>
              <li>{{ $t('high: 16-20') }}</li>
              <li>{{ $t('above the mean: 21-28') }}</li>
              <li>{{ $t('below the mean: 29-32') }}</li>
              <li>{{ $t('low: > 32') }}</li>
          </ol>
      </p>
      <p>
        {{ $t('The scoring for the respondents ‘with treatment’ (mean score 32.4) is as follows:') }}<br><br>
        {{ $t('Number of points:') }}<br>
          <ol>
              <li>{{ $t('high: 16-24') }}</li>
              <li>{{ $t('above the mean: 25-30' )}}</li>
              <li>{{ $t('below the mean: 31-38') }}</li>
              <li>{{ $t('low: > 38') }}</li>
          </ol>
      </p>
      <p>
        {{ $t('The scoring for the total group (mean score 30.1) concerning ‘mature’ religiosity, is as follows:') }}<br><br>
        {{ $t('Number of points:') }}<br>
        <ol>
            <li>{{ $t('high: 16-23') }}</li>
            <li>{{ $t('above the mean: 24-29') }}</li>
            <li>{{ $t('below the mean: 30-35') }}</li>
            <li>{{ $t('low: > 35') }}</li>
        </ol>
      </p>
      <p>
          {{ $t('You can see for yourself which group you belong to for the score. Your number of points is at the top. In addition, the Mature Religiosity Scale (MRS) consists of 3 distinguishable components:') }}<br><br>

          {{ $t('Factor I: Orientation to higher values out of a sense of inner freedom.') }}<br>
          {{ $t('To this factor belong the items 2, 3, 4, 10, 12, 15, 17, 22, 23.') }}<br><br>

          {{ $t('Factor II: Trust in God pervades the entire life.') }}<br>
          {{ $t('To this factor belong the items 1, 5, 8, 11, 13, 18.') }}<br><br>

          {{ $t('Factor III: Responsibility for fellow humans and creation.') }}<br>
          {{ $t('To this factor belong the items 6, 7, 9, 14, 16, 19.') }}<br><br>

          {{ $t('The remaining two qualifying characteristics: items 20, 21.') }}<br><br>

          {{ $t('Factor I concerns the attitude toward oneself, factor II toward God and factor III the attitude toward one’s fellow man and creation. These relations are also recognizable in the biblical golden rule.') }}<br><br>

          {{ $t('A score per factor can be calculated by adding up the total number of points and dividing this by the number of corresponding items: 9 items for factor I, 6 items for factor II, 6 items for factor III.') }}<br>
        </p>
        <p>
          {{ $t('Based on the results and on what you have filled in, you can examine the following for yourself:') }}<br>
          <ul>
              <li>{{ $t('How did I experience completing the list?') }}</li>
              <li>{{ $t('Have there been statements that made me think? Which ones and why?') }}</li>
              <li>{{ $t('Are there aspects that I would like to pay more attention to? For example because I noticed an intertwining with problems in my daily life and want to gain more insight into them.') }}</li>
              <li>{{ $t('Do I experience my beliefs, religion or world-view mainly as helping or hindering my life?') }}</li>
              <li>{{ $t('Are there items on which I scored significantly different from the overall score? What would be the reason?') }}</li>
              <li>{{ $t('Is there a balance between the 3 factors or more an imbalance? Is that recognizable in my daily life?') }}</li>
              <li>{{ $t('Do I see a relationship of the results with the answering of the general questions such as membership or commitment to a particular faith, religion or world-view, attending a church service or religious gathering, the view of Jesus, praying and reading the Bible or a different religious /spiritual book?') }}</li>
          </ul>
        </p>
        <p>
          {{ $t('Reflect on this and/or talk to others about it if you would like.') }}<br><br>
          {{ $t('After a while, you might consider going through the list again or filling it in again. Then you can see whether you have made progress on the components you have chosen yourself.') }}<br><br>
          {{ $t('If you would like to receive the results, enter your email address here. The email address is of course not used for anything else and is never stored.') }}<br><br>
        </p>

        <b-card class="mb-6 send-form">
          <b-row>
            <b-col md="8" class="mb-2">
              <label>{{ $t('Email:') }}</label>
              <b-form-input type="email" name="email" v-model="email" size="lg" />
            </b-col>
            <b-col class="mb-2">
              <label>&nbsp;</label>
              <b-button @click="sendResult" variant="primary" size="lg" style="width:100%;">
                {{ $t('Send') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>

      {{ $t('Hopefully participating has meant something to you.') }}<br><br>

      {{ $t('Margreet de Vries-Schot, Psychiatrist-Psychotherapist and Theologian') }}<br><br><br>

      <b-alert variant="secondary" show class="mt-4 mb-4 text-center">
        {{ uuid }}
      </b-alert>
    </div>
  </Web>
</template>

<script>
// @ is an alias to /src
import Web from '@/layouts/Web'
import axios from 'axios'

export default {
  data() {
    return {
      email: null,
      uuid: null,
      score: null,
    }
  },
  components: {
    Web
  },
  methods: {
    getResult: function() {
      axios.get("https://api.maturereligiosityscale.com/result/score/" +  this.uuid + '/' + this.$i18n.locale)
        .then(response => {
            this.score = response.data.mrs_score;
        });
    },
    sendResult: function() {
      axios.post("https://api.maturereligiosityscale.com/result/mail/" +  this.uuid, { 'email': this.email })
        .then(response => {
            response;
            // console.log(response);
        });
    }
  },
  created() {
    this.uuid = this.$route.params.uuid;
    this.getResult();
  },
}
</script>

<style>
 .table td{
  padding:10px !important;
 }
 .send-form{
  
 }
</style>